import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { toast } from 'react-hot-toast';


const firebaseConfig = {
  apiKey: "AIzaSyBnC6tizlI3ugFU5mULm1v-XDoxOOMTj3w",
  authDomain: "islamic-sharee-council-uk.firebaseapp.com",
  projectId: "islamic-sharee-council-uk",
  storageBucket: "islamic-sharee-council-uk.appspot.com",
  messagingSenderId: "1065502774630",
  appId: "1:1065502774630:web:8a6d4accfa7d718f7124a7"
  
}

const fire = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();

auth.onAuthStateChanged((user) => {
  if (user) {
    // eslint-disable-next-line 
    const uid = user.uid;
    if(user.emailVerified){
      localStorage.setItem('@^&*$£%$!##%admin@^&*$£%$!##%',user.displayName);
    }
  }
})

export default fire;

const sendEmailVerification = () => {
  const user = firebase.auth().currentUser;

  user.sendEmailVerification()
    .then(() => {
      toast.success('Verification Email Sent')
    })
    .catch((error) => {
      // Error occurred while sending verification email
    });
};

export { sendEmailVerification };