import React from 'react'
import {Link} from 'react-router-dom'
import './css/footer.css'
const Footer = () => {
  return (
    <div className='footer'>
      <h6 className='text-center'>
        All Right Reserved &copy; Islamic Sharee Council {new Date().getFullYear()}
      </h6>
      <p className='text-center mt-2'>
        <Link to='/about us'>About Us</Link>
        |
        <Link to='/privacy policy'>Privacy Policy</Link>
        |
        <Link to='/contact us'>Contact Us</Link>
      </p>
    </div>
  )
}

export default Footer
