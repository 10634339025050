import React, { useState } from 'react'
import Layout from '../components/Layout'
import './CSS/Admin.css'
import Homepost from './admincom/Homepost';
import SignUp from './admincom/SignUp';
import NikkahPost from './admincom/NikkahPost';
import DivorcePost from './admincom/DivorcePost';

const Admin = () => {

  const [homep, setHomep] = useState(true);
  const [sign, setSign] = useState(false);
  const [applicationN, setApplicationN] = useState(false);
  const [applicationD, setApplicationD] = useState(false);

  const handleButtonClick = (divNumber) => {
    if (divNumber === 1) {
      setHomep(true);
      setSign(false);
      setApplicationN(false)
      setApplicationD(false)
    } else if (divNumber === 2) {
      setHomep(false);
      setSign(true);
      setApplicationN(false)
      setApplicationD(false)
    }else if( divNumber === 3){
      setHomep(false)
      setSign(false)
      setApplicationN(true)
      setApplicationD(false)
    }else if(divNumber === 4){
      setHomep(false)
      setSign(false)
      setApplicationN(false)
      setApplicationD(true)
    }
  };
  

  
  return (
    <Layout>
    <div className='a-main'>
      <button onClick={() => handleButtonClick(1)}>Home Post</button>
      <button onClick={() => handleButtonClick(2)}>Signup</button>
      <button onClick={() => handleButtonClick(3)}>Nikkah Details</button>
      <button onClick={() => handleButtonClick(4)}>Divorce Details</button>
    </div>
    <div className='a-main mt-2'>

      {homep &&  <Homepost/>}

      {sign &&   <SignUp/>}

      {applicationN && <NikkahPost/>}

      {applicationD && <DivorcePost/>}
      
    </div>
    </Layout>
  )
}

export default Admin
