import React from 'react'
import Layout from '../components/Layout'
import image from '../assets/image.jpg'
import './CSS/aboutus.css'

const About = () => {
  return (
    <Layout>
      <div className='about'>
        <img src={image} alt='logo' />
        <h3>About Us</h3>
        <p>
        The Islamic Sharia Council serves as an authoritative body within the Islamic legal framework, providing guidance on matters relating to Nikah (marriage) and Talaq (divorce). Concerning Nikah, the council upholds the significance of this sacred union, emphasizing the conditions and prerequisites for a valid marriage in accordance with Islamic principles. It offers counsel on the rights and responsibilities of both spouses, emphasizing mutual respect and support within the marital bond. Similarly, in matters of divorce, the council provides guidance on the various types of divorce recognized in Islamic law, highlighting the procedures, rights, and obligations involved in dissolution. It aims to facilitate fair and just proceedings, emphasizing reconciliation where possible and ensuring adherence to Islamic principles of fairness and equity for both parties involved.
        </p>
      </div>
    </Layout>
  )
}

export default About