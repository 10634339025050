import React, { useState ,useEffect} from "react";
import { toast } from "react-hot-toast";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./CSS/homepost.css";

const Homepost = () => {
  const [title, setTitle] = useState("");
  const [cat, setCat] = useState('');
  const [description, setDescription] = useState("");
  const [id, setId] = useState("");
  const [temp, setTemp] = useState("");
  const [hp, setHp] = useState('');

  const name = localStorage.getItem("@^&*$£%$!##%admin@^&*$£%$!##%");
  const db = firebase.firestore();
  const itemsRef = db.collection("HomePost");

  useEffect(() => {
    loadCat()
  }, []);

  const loadCat = async () => {
    const dataArray = [];
    const db = firebase.firestore();
    db.collection("HomePost").orderBy('createdAt').get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          dataArray.push({ id: doc.id, ...doc.data() });
        });
        setHp(dataArray);
      });
  }

  const create = async (e) => {
    try {
      if (!title) {
        return toast.error("Title is Required");
      }
      if (!cat) {
        return toast.error("Catagory is Required");
      }
      if(!description){
        return toast.error('Description is required');
      }
      /**  */
      const query = itemsRef.where("title", "==", title);
      query.get().then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          // eslint-disable-next-line
          const doc = querySnapshot.docs[0];
          toast.error("Title Already in Use");
          return;
        } else {
          itemsRef
            .add({
              title,
              cat,
              description,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then((docRef) => {
              toast.success("Post Added");
              setTitle("");
              setCat("");
              setDescription('');
            })
            .catch((error) => {
              toast.error("Error in Adding New Post");
            });
        }
      });
    } catch (error) {
      console.log(error);
      toast.error("Something went Wrong");
    }
  };

  const read = async (e) => {
    e.preventDefault();
    if (!title) {
      return toast.error("Title is Required");
    }
    const query = itemsRef.where("title", "==", title);
    query
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            setTitle(title);
            setTemp(title);
            setId(doc.id);
            setCat(doc.data().cat);
            setDescription(doc.data().description);
            toast.success("Post Details Loaded");
          });
        } else {
          toast.error("Post Not Foound");
        }
      })
      .catch((error) => {
        toast.error("Error Getting Product ");
      });
  };

  const update = async (e) => {
    e.preventDefault();
    if (!title) {
      return toast.error("Please Enter Title and Click on Get Post");
    }
    if (id === "") {
      return toast.error("Click on Get Post");
    }

    try {
      if (!cat) {
        return toast.error("Catagory is Required");
      }
      if(!description){
        return toast.error('Description is required');
      }
      if (temp === title) {
        const docRef = itemsRef.doc(id);
        docRef
          .update({
            title,
            cat,
            description,
          })
          .then((data) => {
            toast.success("Post Updated Successfully");
            setTitle("");
            setCat("");
            setId("");
            setDescription("");
          })
          .catch((error) => {
            toast.error("Error in Updating Post");
          });
      } else {
        const query = itemsRef.where("title", "==", title);
        query.get().then((querySnapshot) => {
          if (querySnapshot.size > 0) {
            // eslint-disable-next-line
            const doc = querySnapshot.docs[0];
            toast.error("Title Already in Use");
            return;
          } else {
            const docRef = itemsRef.doc(id);
            docRef
              .update({
                title,
                cat,
                description,
              })
              .then((data) => {
                toast.success("Post Updated Successfully");
                setTitle("");
                setCat("");
                setId("");
                setDescription("");
              })
              .catch((error) => {
                toast.error("Error in Updating Post");
              });
          }
        });
      }
    } catch (error) {
      toast.error("Something went Wrong");
    }
  };
  const deletee = async (e) => {
    e.preventDefault();
    if (!title) {
      return toast.error("Please Enter Title and Click on Get Post");
    }
    if (id === "") {
      return toast.error("Click on Get Post");
    }
    try {
      const docRef = itemsRef.doc(id);
      docRef
        .delete()
        .then((data) => {
          toast.success("Post Deleted Successfully");
          setTitle("");
          setCat("");
          setId("");
          setDescription("");
        })
        .catch((error) => {
          toast.error("Post not Deleted");
        });
    } catch (error) {
      toast.error("Something went Wrong");
    }
  };

  return (
    <div className="p-main">
      <h2>Admin {name}</h2>
      <div className="a-main mt-2">
          <div className="p-main">
            <h4 className="mt-3">Home Posts</h4>
            <h4 className="m-3">List of Title of Home Post</h4>
                {hp !== ""
                ? hp.map((p, i) =>
                      <div className="mb-2 ltitle">
                        <h6>{i+1 +'. '  + p.title}</h6>
                      </div>
                )
                : <h4>"No Info found"</h4>}
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="form-control"
              placeholder="Title "
              required
              autoFocus
            />

            <select value={cat} onChange={(e) => setCat(e.target.value)}>
              <option value=''>Select Position</option>
              <option value='Top'>Top</option>
              <option value='Center'>Center</option>
              <option value='End'>End</option>
            </select>

            <textarea
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="form-control"
              placeholder="Type Here"
            />


            <div className="mb-3 bbtn">
              <button onClick={create}>Add Post</button>
              <button onClick={read}>Get Post</button>
              <button onClick={update}>Update Post</button>
              <button onClick={deletee}>
                Delete Post
              </button>
            </div>
          </div>
      </div>
    </div>
  );
};

export default Homepost;