import React,{useState, useRef} from 'react'
import Layout from '../components/Layout'
import { toast } from "react-hot-toast";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import './CSS/contactus.css'
import fire from '../components/fire';

const ResetPassword = () => {

  
  const [email, setEmail] = useState("");
  

  const form = useRef();

  const sendMail = async (e) => {
    e.preventDefault();
    if (email === "") {
      return toast.error("Please Enter your Email");
    } 
    const db = firebase.firestore();
    db.collection('userspres').where('email', '==' , email).get()
    .then(async (querySnapshot) => {
        if(querySnapshot.size > 0){
            await fire.auth().sendPasswordResetEmail(email);
            toast.success('Password Reset Link is sent.Check your Email')
            setEmail('')
        }else{
            return toast.error('This Email is not Registered')
        }
    })
  };
  return (
    <Layout>
      <div className="mform-container">
        <h2 className="contact-t">Forgot Password</h2>
        <form ref={form} onSubmit={sendMail}>
          <h6>Enter you Email to Get Password Reset Link</h6>
          <div className="mb-3 iibox">
            <input
              className='inbox'
              name="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <button type="submit" className="btn" >
              Send Link
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default ResetPassword
