import React,{useState, useRef} from 'react'
import Layout from '../components/Layout'
import { toast } from "react-hot-toast";
import emailjs from "@emailjs/browser";
import './CSS/contactus.css'

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    if (name === "" || email === "" || message === "") {
      alert("Please fill all fields!");
    } else {
      emailjs
        .sendForm(
          'service_kgfm7vq',
          'template_4ewf6hy',
          form.current,
          "8qIEn_l0FHszizRdw"
        )
        .then(
          (result) => {
            toast.success("Email Sent Successfully")
            setName('')
            setEmail('')
            setMessage('')
          },
          (error) => {
            toast.error('Something went wrong! Try again')
          }
        );
    }
  };
  return (
    <Layout>
      <div className="mform-container">
        <h2 className="contact-t">Contact Us</h2>
        <form ref={form} onSubmit={sendEmail}>
          <h6>How can we help you ?</h6>
          <h4>For Questions or Quotes:</h4>
          <div className="mb-3">
            <input
              className='inbox'
              name="name"
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <div className="mb-3 iibox">
            <input
              className='inbox'
              name="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="mb-3 iibox">
            <textarea
              className='inbox'
              name="message"
              type="text"
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <button type="submit" className="btn" >
              Submit
            </button>
          </div>
        </form>
       <div className="mt-2 quot">
       <li>Head Office Birmingham</li>
       <li>Phone: +44 7976777275</li>
       <li>Email: farooqvakeel@outlook.com</li>
       <p className='mt-1 mb-2'>
       Thank you for contacting Islamic Sharee Council. We appreciate your inquiry and are here to assist you.
        </p>
       </div>
      </div>
    </Layout>
  )
}

export default Contact
