import React from 'react'
import Header from './Header';
import Footer from './Footer';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from '../assets/logo.jpg'
import  { Toaster } from 'react-hot-toast';
import './css/layout.css'

const Layout = (props) => {
  return (
    <div>
      <Header/>
      <main className='lay-main' style={{minHeight: '80vh'}}>
      <Toaster />
       {props.children}
       <FloatingWhatsApp accountName='Islamic Sharee Council' phoneNumber='+447932060509' allowEsc={true}
          statusMessage='We will reply shortly' avatar={logo} notification={true} 
        />
      </main>
      <Footer/>
    </div>
  )
}

export default Layout
