import React, { useState } from 'react'
import Layout from '../components/Layout'
import './CSS/Admin.css'
import Nikkah from './applications/Nikkah';
import Divorce from './applications/Divorce';

const Applicationform = () => {

  const [nikkah, setNikkah] = useState(true);
  const [divorce, setDivorce] = useState(false);

  const handleButtonClick = (divNumber) => {
    if (divNumber === 1) {
      setNikkah(true);
      setDivorce(false);
    } else if (divNumber === 2) {
      setNikkah(false);
      setDivorce(true);
    }
  };
  

  
  return (
    <Layout>
    <div className='a-main'>
      <button onClick={() => handleButtonClick(1)}>Nikkah</button>
      <button onClick={() => handleButtonClick(2)}>Divorce</button>
    </div>
    <div className='a-main mt-2'>

      {nikkah &&  <Nikkah/>}

      {divorce &&   <Divorce/>}

    </div>
    </Layout>
  )
}

export default Applicationform
