import React, { useState, useEffect } from 'react'
import './CSS/nikkah.css'
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import ReactMarkdown from 'react-markdown';


const Nikkah = () => {

  const [bride, setBride] = useState([])
  const [groom, setGroom] = useState([])

  useEffect(() => {
    const dataArray = [];
    const db = firebase.firestore();
    db.collection('NikkahDocuments').get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          dataArray.push({ id: doc.id, ...doc.data() });
        });
        setBride(dataArray)
        //setBride(dataArray.filter(item => item.title === 'Bride'))
        //setGroom(dataArray.filter(item => item.title === 'Groom'))
      })
  }, []);

  return (
    <div className='n-main'>
      <h3>Details Required for Performing Nikkah</h3>
      <div className='nrow'>
        <div className='n-subdiv m-5'>
          {bride.map((b) => {
            return (
              <div className="mb-2" key={b.title} id={b.id}>
                <h2 className="n-title">{b.title}</h2>
                <ReactMarkdown className="n-description">
                  {b.desc}
                </ReactMarkdown>
              </div>
            )
          })}
        </div>
        <div className='n-subdiv m-5'>
          {groom.map((b) => {
            return (
              <div className="mb-2" key={b.title} id={b.id}>
                <h2 className="n-title">{b.title}</h2>
                <ReactMarkdown className="n-description">
                  {b.desc}
                </ReactMarkdown>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default Nikkah