import React,{useState} from 'react'
import Layout from '../components/Layout'
import { toast } from "react-hot-toast";
import './CSS/login.css'
import fire from '../components/fire'
import "firebase/compat/firestore";
import { Link } from 'react-router-dom';

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
      e.preventDefault();
       fire
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // User logged in successfully
          const isEmailVerified = localStorage.getItem('@^&*$£%$!##%admin@^&*$£%$!##%')

          if(isEmailVerified != null){
            const user = userCredential.user;
          user.getIdToken().then((token) => {
            // You can access the token here
            localStorage.setItem('@^&*$£%$!##%auth%##@^&*$£%$!',token+'37fpxwt')
            toast.success("Login Successful");
            window.location.replace(`/admin/${token+'37fpxwt'}`);
          })
          }else{
           return toast.error('Verify your Email first')
        }
          
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
      
    }

  return (
    <Layout>
    
    <div className="mform-container">
        <h2 className="contact-t">Admin Login</h2>
        <form onSubmit={handleSubmit}>

        <div className="mb-3 iibox">
            <input
              className='inbox'
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <input
              className='inbox'
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <button type="submit" className="btn" >
              Login
            </button>
          </div>
          <Link className='fpsd' to='/reset password'>Forgot Password ?</Link>
        </form>
       <div className="mt-5 quot">
       <p>
       Login is Only for Admin of Islamic Sharee Council
        </p>
       </div>
      </div>
    </Layout>
  )
}

export default Login
