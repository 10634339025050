import React ,{useEffect, useState}from 'react'
import { NavLink } from 'react-router-dom';
import './css/header.css'
import toast from "react-hot-toast";
import logo from '../assets/logo.jpg'
import fire from '../components/fire'
import firebase from 'firebase/compat/app';

const Header = () => {

  const [auth1,setAuth1] = useState('')
//eslint-disable-next-line
  useEffect(() => {
    const logoutAfterOneDay = () => {
      const oneDayMilliseconds = 24 * 60 * 60 * 1000; // 1 day in milliseconds
      setTimeout(() => {
        localStorage.clear();
        setAuth1('')
        firebase.auth().signOut();
        window.location.href = '/login'
        toast.success("Session Expired.");

      }, oneDayMilliseconds);
    };
    setAuth1(localStorage.getItem('@^&*$£%$!##%auth%##@^&*$£%$!'))
    if (auth1 !== '') {
      logoutAfterOneDay();
    }
    return () => clearTimeout(logoutAfterOneDay);
  });

  const handleLogout = () => {
    
    localStorage.clear();
    fire.auth().signOut().then(() => {
      toast.success("Logout Successfully");
    }).catch((error) => {
      // Handle errors
      console.error(error);
  })
}

  return (
    <div>
      <nav className="navbar navbar-expand-lg ">
        <div className="mnav container-fluid">
          <button
            className="navbar-toggler bg-light"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <img src={logo} alt='Logo' className='iImg'/>
          <text className="navbar-brand mt-2">
            <h4>Islamic Sharee Council</h4>
            
          </text>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link"  to='/'>
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link"  to='/our services'>
                  Services
                </NavLink>
              </li>
              {!auth1 ? (
                <li className="nav-item">
                  <NavLink to="/login" className="nav-link">
                    Login
                  </NavLink>
                </li>
              ) : (
                <>
                <li className="nav-item">
                  <NavLink
                    onClick={handleLogout}
                    to="/login"
                    className="nav-link"
                  >
                    Logout
                  </NavLink>
                </li>
                <li className="nav-item">
                <NavLink
                  to={`/admin/${auth1}`}
                  className="nav-link"
                >
                  Admin
                </NavLink>
              </li>
              </>
              )}
              
              <li className="nav-item">
                <NavLink className="nav-link" to='/contact us'>
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header
