import React, { useState, useEffect } from 'react'
import './CSS/divorce.css'
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import ReactMarkdown from 'react-markdown';


const Divorce = () => {

  const [ddoc, setDdoc] = useState([])

  useEffect(() => {
    const dataArray = [];
    const db = firebase.firestore();
    db.collection('DivorceDocuments').get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          dataArray.push({ id: doc.id, ...doc.data() });
        });
        setDdoc(dataArray)
        //setGroom(dataArray.filter(item => item.title === 'Groom'))
      })
  }, []);

  return (
    <div className='d-main'>
      <h3>Details Required for Tanseekh-e-Nikkah</h3>
      <div className='drow'>
        <div className='m-5'>
          {ddoc.map((b) => {
            return (
              <div className="mb-2" key={b.title} id={b.id}>
                <h2 className="post-title">{b.title}</h2>
                <ReactMarkdown className="post-description">
                  {b.desc}
                </ReactMarkdown>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default Divorce