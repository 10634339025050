import React, { useState , useEffect} from 'react'
import Layout from '../components/Layout';
import Banner from '../components/Banner'
import './CSS/home.css'
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import ReactMarkdown from 'react-markdown';
import qadri from '../assets/qadri.jpeg'
import ahazrat from '../assets/ahazrat.jpeg'



const Home = () => {

  const [blogs, setBlogs]= useState([])
  const [tcat,setTcat] = useState([])
  const [ccat,setCcat] = useState([])
  const [ecat,setEcat] = useState([])

  useEffect(() => {
    const dataArray = [];
    const db = firebase.firestore();
    db.collection('HomePost').orderBy('createdAt').get()
    .then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      dataArray.push({id: doc.id,...doc.data()});
    });
    setBlogs(dataArray);
    setTcat(dataArray.filter(item => item.cat === 'Top'))
    setCcat(dataArray.filter(item => item.cat === 'Center'))
    setEcat(dataArray.filter(item => item.cat === 'End'))
  })
  }, []);

  const scrollToSection = (event) => {
    event.preventDefault();
    const id = event.target.getAttribute("href").substring(1);
    const element = document.getElementById(id);
    if (element) {
      element.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Layout>
      <Banner/>
      <div className="my-container" style={{ minHeight: "82vh" }}>
      
        <div>
          <button
            className="btntc mt-3 mb-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseWidthExample"
            aria-expanded="false"
            aria-controls="collapseWidthExample"
            aria-label="Toggle navigation"
          >
            <h4 className="m-2"> Table of Content </h4>
          </button>
          
          <div className="collapse collapse-verticle" id="collapseWidthExample">
            <div className='mybody'>
              <ol className="card card-body">
                <h4 className="m-2">Topics</h4>
                <div className="mt-0 mb-0 m-3">
                  {blogs.map((t) => {
                    return (
                      <li key={t.id}>
                        <a href={`#${t.id}`} onClick={() => scrollToSection}>
                          {t.title}
                        </a>
                      </li>
                    );
                  })}
                </div>
              </ol>
            </div>
          </div>
        </div>
        <div className='myimg'>
        <img src={ahazrat} alt='Card' className='m-3'/>
        <img src={qadri} alt='Card' className='m-3'/>
        </div>
        <audio className='m-4 ad' controls>
        <source className='ad1' src='/isc.mp3' type="audio/mp3" />
      </audio>
      <h5>Advice and Guidance Information ⬆️</h5> 
      <h6><strong>Email :</strong> farooqvakeel@outlook.com</h6>
      <h6><strong>Ph. Number :</strong> +44 7976 777275</h6>
      <h6><strong>WhatsApp :</strong> +44 7932 060509</h6>
        <div>
          {tcat.map((b) => {
            return (
              <article className="blog-post mt-5 mb-2" key={b.title} id={b.id}>
                <h2 className="post-title">{b.title}</h2>
                <ReactMarkdown className="post-description">
                  {b.description}
                </ReactMarkdown>
              </article>
            )
          })}
          {ccat.map((b) => {
            return (
              <article className="blog-post mt-5 mb-2" key={b.title} id={b.id}>
                <h2 className="post-title">{b.title}</h2>
                <ReactMarkdown className="post-description">
                  {b.description}
                </ReactMarkdown>
              </article>
            )
          })}
          {ecat.map((b) => {
            return (
              <article className="blog-post mt-5 mb-2" key={b.title} id={b.id}>
                <h2 className="post-title">{b.title}</h2>
                <ReactMarkdown className="post-description">
                  {b.description}
                </ReactMarkdown>
              </article>
            )
          })}
        </div>
      </div>
    </Layout>
  );
}

export default Home