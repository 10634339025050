import React,{useState, useEffect} from 'react'
import { Outlet } from 'react-router-dom'
import Spinner from './Spinner'


export default function PrivateRoute () {

    const [ok,setOk] = useState(false)

    useEffect(() => {
              const usern = localStorage.getItem('@^&*$£%$!##%auth%##@^&*$£%$!')
                if(usern !== null){
                    setOk(true)
                }else{
                    setOk(false)
                }
    },[])

    return ok ? <Outlet/> : <Spinner/>
}


