import React, { useState } from 'react'
import './CSS/signup.css'
import { toast } from "react-hot-toast";
import fire from '../../components/fire'
import { sendEmailVerification } from '../../components/fire';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


const SignUp = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const db = firebase.firestore();
  const itemsRef = db.collection('userspres');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ch = fire.auth().fetchSignInMethodsForEmail(email)
    if (ch === email) {
      return toast.error('Email already registered!')
    }
    fire.auth().createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        user.updateProfile({
          displayName: name,
        })
        itemsRef
          .add({
            email,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(docRef => {
            sendEmailVerification()
            toast.success('Signup Successful')
            setName('')
            setEmail('')
            setPassword('')
          })
          .catch(error => {
            return toast.error('Something went Wrong Please try again')
          });
      })
  }


  return (
    <div className="mform-container">
      <h2 className="contact-t">Admin SignUp</h2>
      <form onSubmit={handleSubmit}>

        <div className="mb-3 ">
          <input
            className='inbox'
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <input
            className='inbox'
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <input
            className='inbox'
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <div className="mb-2">
          <button type="submit" className="btn" >
            Create Account
          </button>
        </div>
      </form>
    </div>
  )
}

export default SignUp
