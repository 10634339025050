import {Routes,Route} from 'react-router-dom'
import Home from './screens/Home';
import Applicationform from './screens/Applicationform';
import Contact from './screens/Contact';
import About from './screens/About';
import Privacypolicy from './screens/Privacypolicy';
import Pagenotfound from './screens/Pagenotfound';
import Login from './screens/Login';
import Admin from './screens/Admin';
import PrivateRoute from './components/PrivateRoute';
import ResetPassword from './screens/ResetPassword';

function App() {
  const auth1 = localStorage.getItem('@^&*$£%$!##%auth%##@^&*$£%$!');
  return (
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/our services' element={<Applicationform/>}/>
      <Route path='/contact us' element={<Contact/>} />
      <Route path='/about us' element={<About/>} />
      <Route path='/privacy policy' element={<Privacypolicy/>} />
      <Route path='/login' element={<Login/>}/>
      <Route path='/reset password' element={<ResetPassword/>}/>
      <Route path='' element={<PrivateRoute/>}>
        <Route path={`/admin/${auth1}`} element={<Admin/>}/>
      </Route>
      <Route path='/*' element={<Pagenotfound/>} />
    </Routes>
  );
}

export default App;
