import React, { useState,useEffect } from "react";
import { toast } from "react-hot-toast";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./CSS/homepost.css";

const DivorcePost = () => {

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("")
  const [id, setId] = useState("");
  const [temp, setTemp] = useState("");
  const [ddoc, setDdoc] = useState([]);

  const name = localStorage.getItem("@^&*$£%$!##%admin@^&*$£%$!##%");
  const db = firebase.firestore();
  const itemsRef = db.collection("DivorceDocuments");

  useEffect(() => {
    loadCat()
  }, []);

  const loadCat = async () => {
    const dataArray = [];
    const db = firebase.firestore();
    db.collection("DivorceDocuments").get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          dataArray.push({ id: doc.id, ...doc.data() });
        });
        setDdoc(dataArray);
      });
  }

  const create = async (e) => {
    try {
      if (!title) {
        return toast.error("Title is Required");
      }
      if(!desc){
        return toast.error('Please enter Description')
      }
      
      const query = itemsRef.where("title", "==", title);
      query.get().then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          // eslint-disable-next-line
          const doc = querySnapshot.docs[0];
          toast.error("Title already exists");
          return;
        } else {
          itemsRef
            .add({
              title,
              desc,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then((docRef) => {
              toast.success("Info added to List");
              loadCat();
              setTitle("");
              setDesc('');
            })
            .catch((error) => {
              toast.error("Error in Adding Info to List");
            });
        }
      });
    } catch (error) {
      console.log(error);
      toast.error("Something went Wrong");
    }
  };

  const read = async (e) => {
    e.preventDefault();
    if (!title) {
      return toast.error("Title is Required");
    }
    const query = itemsRef.where("title", "==", title);
    query
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            setTitle(title);
            setTemp(title);
            setId(doc.id);
            setDesc(doc.data().desc)
            toast.success("Title Founded");
          });
        } else {
          toast.error("Title Not Foound");
        }
      })
      .catch((error) => {
        toast.error("Error Getting Title ");
      });
  };

  const update = async (e) => {
    e.preventDefault();
    if (!title) {
      return toast.error("Please Enter Title and Click on Get Info");
    }
    if (id === "") {
      return toast.error("Click on Get Info");
    }

    try {
      if (temp === title) {
        const docRef = itemsRef.doc(id);
        docRef
          .update({
            title,
            desc,
          })
          .then((data) => {
            toast.success("Info Updated Successfully");
            setTitle("");
            setDesc('')
            loadCat();
          })
          .catch((error) => {
            toast.error("Error in Updating Info");
          });
      } else {
        const query = itemsRef.where("title", "==", title);
        query.get().then((querySnapshot) => {
          if (querySnapshot.size > 0) {
            // eslint-disable-next-line
            const doc = querySnapshot.docs[0];
            toast.error("Title Already in Use");
            return;
          } else {
            const docRef = itemsRef.doc(id);
            docRef
              .update({
                title,
                desc,
              })
              .then((data) => {
                toast.success("Info Updated Successfully");
                setTitle("");
                setDesc('')
                loadCat();
              })
              .catch((error) => {
                toast.error("Error in Updating Info");
              });
          }
        });
      }
    } catch (error) {
      toast.error("Something went Wrong");
    }
  };
  const deletee = async (e) => {
    e.preventDefault();
    if (!title) {
      return toast.error("Please Enter Title and Click on Get Info");
    }
    if (id === "") {
      return toast.error("Click on Get Info");
    }
    try {
      const docRef = itemsRef.doc(id);
      docRef
        .delete()
        .then((data) => {
          toast.success("Info Deleted Successfully");
          setTitle("");
          setDesc('')
          loadCat();
        })
        .catch((error) => {
          toast.error("Info not Deleted");
        });
    } catch (error) {
      toast.error("Something went Wrong");
    }
  };

  return (
    <div className="p-main">
      <h2>Admin {name}</h2>
        <div className="a-main mt-2">
            <div className="p-main">
            <h4 className="mt-3">Divorce Posts</h4>
            <h4 className="m-3">List of Title Divorce Posts</h4>
                {ddoc !== ""
                ? ddoc.map((p, i) =>
                      <div className="mb-2 ltitle">
                        <h6>{i+1 +'. '  + p.title}</h6>
                      </div>
                )
                : <h4>"No Info found"</h4>}
              <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="form-control"
              placeholder="Title "
              required
              autoFocus
                />

              <textarea
              type="text"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              className="form-control"
              placeholder="Type Here Description"
              required
                />

            
                <div className="mb-3 bbtn">
                    <button onClick={create}>Add to List</button>
                    <button onClick={read}>Get from List</button>
                    <button onClick={update}>Update </button>
                    <button onClick={deletee} className=" mt-2">
                        Delete 
                    </button>
                </div>
            </div>
        </div>
    </div>
  );
};

export default DivorcePost;